<template>
  <section class="main">
    <h1 class="title">
      {{ material.title }}
    </h1>
    <div class="body" v-html="material.contentHtml"></div>
  </section>
</template>

<script>
import { mdUtil } from "@/util/mdUtil";
import { tikuApi } from "@/api/tikuApi";

export default {
  data() {
    return {
      material: null,
    };
  },
  components: {},
  mounted() {
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    if (id && id > 0 && subjectCourseId && subjectCourseId > 0) {
      tikuApi.getMaterial(subjectCourseId, id).then((ret) => {
        console.log(ret);
        let { code: code, data: data } = ret;
        if (code == 0) {
          this.material = data;
          this.material.contentHtml = mdUtil.richTextUnitToHtml(this.material.content);
        }
      });
    }
  },
  methods: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  text-align: left;
}
</style>
